import PropTypes from "prop-types";
// material
import { alpha } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from "@mui/material";
// components
import Iconify from "src/components/Iconify";
//
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import Logo from "src/components/branded/Logo";
import TopNavSection from "src/components/TopNavSection";
import navConfig from "./NavConfig";
import { useState } from "react";
import AuthenticationModal from "src/features/authentication/AuthenticationModal";

// ----------------------------------------------------------------------

// These are in px
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

// ----------------------------------------------------------------------

PortalNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  user: PropTypes.any,
};

export default function PortalNavbar({
  onOpenSidebar,
  topOffset,
  sideBarDisplay,
  authenticated,
}) {
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const onModalClose = () => {
    setAuthModalOpen(false);
  };
  return (
    <>
      <AuthenticationModal
        open={authModalOpen}
        onClose={onModalClose}
        onAuthenticated={onModalClose}
      />
      <AppBar
        sx={(theme) => ({
          boxShadow: "none",
          backdropFilter: "blur(6px)",
          WebkitBackdropFilter: "blur(6px)",
          backgroundColor: alpha(theme.palette.background.default, 0.72),
          top: topOffset,
          transition: "all 0.3s ease",
          [theme.breakpoints.up("lg")]: {
            width: sideBarDisplay
              ? `calc(100% - ${DRAWER_WIDTH + 1}px)`
              : `100%`,
          },
        })}
      >
        <Toolbar
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            minHeight: APPBAR_MOBILE,
            [theme.breakpoints.up("lg")]: {
              minHeight: APPBAR_DESKTOP,
              padding: theme.spacing(0, 5),
            },
          })}
        >
          <Box sx={{ flexShrink: 1, minWidth: 75 }}>
            <IconButton
              onClick={onOpenSidebar}
              sx={{
                mr: 1,
                color: "text.primary",
                display: { xs: "flex", lg: "none" },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            <Logo size={75} sx={{ display: { xs: "none", lg: "unset" } }} />
          </Box>

          <Box
            sx={(theme) => ({
              display: "flex",
              flexGrow: 1,
              alignText: "center",
              justifyContent: "center",
              [theme.breakpoints.up("lg")]: {
                justifyContent: "end",
              },
            })}
          >
            <Logo
              size={APPBAR_MOBILE}
              sx={{
                display: { xs: "unset", lg: "none" },
                margin: "auto",
                textAlign: "center",
              }}
            />
            {/* <Searchbar /> */}
            {!sideBarDisplay && (
              <TopNavSection
                navConfig={navConfig}
                sx={{ display: { xs: "none", lg: "flex" } }}
              />
            )}
          </Box>

          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              minWidth: "75px",
              justifyContent: "right",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              {/* <LanguagePopover /> */}
              {authenticated ? (
                <>
                  <NotificationsPopover />
                  <AccountPopover />
                </>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setAuthModalOpen(true)}
                >
                  Sign in
                </Button>
              )}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
