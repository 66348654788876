import { Box, Stack, Typography } from "@mui/material";
import { BaseOptionChartStyle } from "src/components/chart/BaseOptionChart";
import SelectedPartnerProvider from "src/components/contexts/SelectedPartnerContext";
import { useAuth } from "src/components/contexts/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import TopBanner from "src/components/TopBanner";
import PortalNavbar from "./portal/PortalNavbar";
import PortalSidebar from "./portal/PortalSidebar";
import { Suspense, useState } from "react";
import Spinner from "src/components/branded/Spinner";
import Footer from "src/components/Footer";
import useResponsive from "src/hooks/useResponsive";
import AuthenticationModal from "src/features/authentication/AuthenticationModal";
import { useSnackbar } from "notistack";

// These are in px
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const ALLOW_BANNER = true;
const BANNER = 60;

export default function Layout({ mode }) {
  const { auth } = useAuth();
  const authenticated = auth.authenticated;
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const onModalClose = () => {
    setAuthModalOpen(false);
  };

  const barDisplay = {};
  switch (mode) {
    case "authenticated":
      barDisplay.navBarDisplay = true;
      barDisplay.sideBarDisplay = !isDesktop;
      break;
    case "public":
      barDisplay.navBarDisplay = true;
      barDisplay.sideBarDisplay = !isDesktop;
      break;
    case "unauthenticated":
      barDisplay.navBarDisplay = false;
      barDisplay.sideBarDisplay = false;
      break;
    // Default case should never happen
    default:
      barDisplay.navBarDisplay = true;
      barDisplay.sideBarDisplay = !isDesktop;
      break;
  }

  const displayTopBanner =
    ALLOW_BANNER && mode !== "unauthenticated" && !authenticated;

  const displayFooter = mode === "public";

  // get the location originally requested (if applicable)
  let { from } = location.state || {};
  const destination = (from && from.pathname) || "/";

  if (mode === "unauthenticated" && authenticated) {
    return <Navigate to={destination} />;
  }

  if (mode === "authenticated" && !authenticated) {
    enqueueSnackbar("Must be logged on to access that page");
    return <Navigate to="/" />;
  }
  return (
    <SelectedPartnerProvider>
      <Box
        sx={{
          display: "flex",
          minHeight: "100%",
        }}
      >
        <BaseOptionChartStyle />
        {displayTopBanner && (
          <>
            <AuthenticationModal
              open={authModalOpen}
              onClose={onModalClose}
              onAuthenticated={onModalClose}
              initialScreenToShow="register"
            />
            <TopBanner
              bannerHeight={BANNER}
              isScrolled={isScrolled}
              setIsScrolled={setIsScrolled}
              onClick={() => setAuthModalOpen(true)}
            >
              <Typography>Create an Account Free Today!</Typography>
            </TopBanner>
          </>
        )}
        {barDisplay.navBarDisplay && (
          <PortalNavbar
            onOpenSidebar={() => setOpen(true)}
            topOffset={displayTopBanner && !isScrolled ? 60 : 0}
            sideBarDisplay={barDisplay.sideBarDisplay}
            authenticated={authenticated}
          />
        )}
        {barDisplay.sideBarDisplay && (
          <PortalSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            authenticated={authenticated}
          />
        )}
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          flexGrow={1}
          minHeight={"100vh"}
          sx={(theme) => ({
            paddingTop: APP_BAR_MOBILE + BANNER * displayTopBanner + "px",
            [theme.breakpoints.up("lg")]: {
              paddingTop: APP_BAR_DESKTOP + BANNER * displayTopBanner + "px",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              flexGrow: 1,
              paddingBottom: theme.spacing(10),
              [theme.breakpoints.up("lg")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              },
            })}
          >
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </Box>
          {displayFooter && <Footer />}
        </Stack>
      </Box>
    </SelectedPartnerProvider>
  );
}
