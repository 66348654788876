import { createContext, useContext, useState } from "react";
import { useGetDefaultPartners } from "src/hooks/user";
import {
  loadFromSessionStorage,
  saveToSessionStorage,
} from "src/services/sessionStorage";

export const SelectedPartnerContext = createContext({
  selectedPartnerId: null,
  setSelectedPartnerId: null,
});

export default function SelectedPartnerProvider({ children }) {
  //Set up inital state
  const defaultPartnerQuery = useGetDefaultPartners();
  const defaultPartner = defaultPartnerQuery?.data?.entries?.[0];
  //Using session storage to persist between hard refreshes
  const [selectedPartnerId, setSelectedPartnerId] = useState(
    loadFromSessionStorage("selectedPartnerId")
  );
  const setSelectedPartnerIdAndSaveToStorage = (id) => {
    setSelectedPartnerId(id);
    saveToSessionStorage("selectedPartnerId", id);
  };

  // Depending on whether we allow users to have no partner selected, we may need a different method of using the default
  return (
    <SelectedPartnerContext.Provider
      value={{
        selectedPartnerId: !selectedPartnerId
          ? defaultPartner?.partnerId
          : selectedPartnerId,
        setSelectedPartnerId: setSelectedPartnerIdAndSaveToStorage,
      }}
    >
      {children}
    </SelectedPartnerContext.Provider>
  );
}

export const useSelectedPartner = () => {
  const { selectedPartnerId, setSelectedPartnerId } = useContext(
    SelectedPartnerContext
  );
  return { selectedPartnerId, setSelectedPartnerId };
};
