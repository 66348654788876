import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import { useAuth } from "src/components/contexts/AuthContext";
import api from "src/services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
const getUsers = async (options) => {
  const { data } = await api.get(`/user?${ObjectToQueryString(options)}`);
  return data;
};

const getCurrentUser = async () => {
  const { data } = await api.get(`/user/current`);
  return data;
};

const addUserRole = async ({ userId, roleId }) => {
  const { data } = await api.post(`/user/${userId}/role/${roleId}`);
  return data;
};

const removeUserRole = async ({ userId, roleId }) => {
  const { data } = await api.delete(`/user/${userId}/role/${roleId}`);
  return data;
};

const getUserNotifications = async (userId, options) => {
  const { data } = await api.get(
    `/notifications/${userId}?${ObjectToQueryString(options)}`
  );
  return data;
};

//create const updateUserNotification for marking notifications as read/unread, or deleting
const updateUserNotification = async (notificationId) => {
  const { data } = await api.put(`/notifications/markAsRead/${notificationId}`);
  return data;
};

const updateAllUserNotifications = async () => {
  const { data } = await api.put("/notifications/markAllAsRead");
  return data;
};

// getUserPartners = Get list of all partners accessible to the user
const getUserPartners = async (options) => {
  const { data } = await api.get(
    `/user/partners?${ObjectToQueryString(options)}`
  );
  return data;
};

const setUserPartnerAsDefault = async (partnerId) => {
  const { data } = await api.put(`/user/partners/${partnerId}/setDefault`);
  return data;
};

// Custom hooks
export const useUserList = (page = 0, perPage = 10) => {
  return useQuery(["userList", { page, perPage }], () =>
    getUsers({ page, perPage })
  );
};
//TODO: Replace above with below!
export const useInfiniteUserList = (filters) => {
  return useInfiniteQuery({
    queryKey: ["userList", filters],
    queryFn: ({ pageParam = 0 }) => {
      return getUsers({ page: pageParam, ...filters });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.pageNumber + 1 < lastPage.totalPages
        ? lastPage.pageNumber + 1
        : undefined;
    },
  });
};

export const useCurrentUser = () => {
  const {
    auth: { authenticated },
  } = useAuth();
  return useQuery(["currentUser"], () => getCurrentUser(), {
    enabled: authenticated,
  });
};

export const useAddUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(addUserRole, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["userList"]);
    },
  });
};

export const useRemoveUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(removeUserRole, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["userList"]);
    },
  });
};

// ToDo: update to account for sortorder (unread first)
export const useUserNotificationList = (userId, page = 0, perPage = 10) => {
  return useQuery(["userNotificationList", { userId, page, perPage }], () =>
    getUserNotifications(userId, { page, perPage })
  );
};

export const useUpdateUserNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userNotificationList"]);
    },
  });
};

//should either be changed to "markAllAsRead or updated to include other functionality (deleting, etc)"
export const useUpdateAllUserNotifications = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ["markAllNotificationsAsRead"],
    () => {
      updateAllUserNotifications();
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(["userNotificationList"]);
      },
    }
  );
};

export const useInfiniteUserPartnerList = (filters) => {
  return useInfiniteQuery({
    queryKey: ["userPartnerList", filters],
    queryFn: ({ pageParam = 0 }) => {
      return getUserPartners({ page: pageParam, ...filters });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.pageNumber + 1 < lastPage.totalPages
        ? lastPage.pageNumber + 1
        : undefined;
    },
  });
};

export const useSetPartnerAsDefault = () => {
  const queryClient = useQueryClient();

  return useMutation(setUserPartnerAsDefault, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["userPartnerList"]);
    },
  });
};

// This returns a list of all default partners, but there should be at most 1
export const useGetDefaultPartners = (
  page = 0,
  perPage = 10,
  getDefault = true
) => {
  return useQuery(["userPartnerList", { page, perPage, getDefault }], () =>
    getUserPartners({ page, perPage, getDefault })
  );
};

// Hooks for convenience, not for fetching data
export const useIsLoggedOn = () => {
  const userQuery = useCurrentUser();
  const user = userQuery.data;

  return isLoggedOn(user);
};

export const isLoggedOn = (user) => user && true;

export const useIsAdmin = () => {
  const userQuery = useCurrentUser();
  const user = userQuery.data;

  return isAdmin(user);
};

export const isAdmin = (user) => {
  if (user && user.roles.some((x) => x === "admin")) {
    return true;
  }
  return false;
};

// This should be somewhere better
const partnerRoles = ["admin", "partner admin", "partner user"];
export const useIsPartnerUser = () => {
  const userQuery = useCurrentUser();
  const user = userQuery.data;

  return isPartnerUser(user);
};

export const isPartnerUser = (user) => {
  if (user && user.roles.some((x) => partnerRoles.includes(x))) {
    return true;
  }
  return false;
};

export const useIsPartnerAdmin = (partnerId) => {
  const userQuery = useCurrentUser();
  const user = userQuery.data;

  return isPartnerAdmin(user, partnerId);
};

export const isPartnerAdmin = (user, partnerId) => {
  if (isAdmin(user)) {
    return true;
  }
  if (user.partnerAdminAccess.includes(Number(partnerId))) {
    return true;
  }
  return false;
};
